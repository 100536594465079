/**
 * This file contains some function stubs(ie incomplete functions) that
 * you MUST use to begin the work for calculating the grades.
 *
 * You may need more functions than are currently here...we highly encourage you to define more.
 *
 * Anything that has a type of "undefined" you will need to replace with something.
 */
import { IUniversityClass, IStudent, IAssignment, IGrade } from "../types/api_types";
import { BASE_API_URL, MY_BU_ID, GET_DEFAULT_HEADERS } from "../globals";


/**
 * This function might help you write the function below.
 * It retrieves the final grade for a single student based on the passed params.
 * 
 * If you are reading here and you haven't read the top of the file...go back.
 */


// Learning about async promise:
// SOURCE: https://medium.com/jspoint/typescript-promises-and-async-await-b842b55ee3fd



/****************************************************************************************
 * START OF MY HELPER FUNCTIONS: 
 * 
 * Input: currClassId
 * 
 * FETCH CLASS INFORMATION, for ONE class
*****************************************************************************************/
export const fetchClassInfo = async (currClassId: string) : Promise <IUniversityClass | undefined> => { 
  // write const res using imports from globals 
  try {
    const res = await fetch(
      `${BASE_API_URL}class/GetById/${currClassId}?buid=${MY_BU_ID}`,
      {
        method: 'GET',
        headers: GET_DEFAULT_HEADERS(),
      }
    );

  const json = await res.json();
  // console.log(json);
  return json;
} catch (error) {
  console.error('Error fetching class list:', error);
}
};


/****************************************************************************************
 * Input: currClassId
 * 
 * FETCH STUDENT LIST 
*****************************************************************************************/
export const fetchStudentList = async (currClassId: string) : Promise <string[]> =>{
  try {
    const res = await fetch(
      `${BASE_API_URL}class/listStudents/${currClassId}?buid=${MY_BU_ID}`,
      {
        method: 'GET',
        headers: GET_DEFAULT_HEADERS(),
      }
    );

    const json = await res.json();
    // console.log(json);
    return json;
  } catch (error) {
    console.error('Error fetching class list:', error);
    throw error; // Re-throw the error to handle it in the caller function.
  }
}

/****************************************************************************************
 * Input: studentID
 * 
 * FETCH STUDENT INFO THAT RETURNS A LIST OF IStudent OBJECTS
*****************************************************************************************/
export const fetchStudentInfo = async (studentID: IStudent | string): Promise <IStudent>=> {
  try {
    const res = await fetch(
      `${BASE_API_URL}student/GetById/${studentID}?buid=${MY_BU_ID}`,
      {
        method: 'GET',
        headers: GET_DEFAULT_HEADERS(),
      }
    );
    const json = await res.json();
    return json[0];
  } catch (error) {
    console.error('Error fetching student names:', error);
    throw error;
  }
};

/****************************************************************************************
 * Input: studentID, currClassId
 * 
 * FETCH STUDENT GRADE LIST THAT RETURNS A LIST OF IGrade OBJECTS
*****************************************************************************************/
export const fetchStudentGradeList = async (studentID: IStudent | String, currClassId: string): Promise <IGrade | undefined >   => { 
  //  write const res using imports from globals 
  try {
   const res = await fetch(
    `${BASE_API_URL}student/listGrades/${studentID}/${currClassId}/?buid=${MY_BU_ID}`,
    {
      method: "GET",
      headers: GET_DEFAULT_HEADERS(),
    }
  );
  const json = await res.json();
  if (json.length === 0) {
    return undefined;
  }
  return json;
} catch (error) {
    console.error('Error fetching student names:', error);
  }
};

/****************************************************************************************
 * input: currClassId
 * 
 * FETCH CLASS ASSIGNMENT WEIGHT, RETURNS A LIST OF IAssignment OBJECTS
*****************************************************************************************/

export const fetchAssignmentWeight = async (currClassId: string) : Promise <IAssignment[] | undefined> => { 
  //  write const res using imports from globals
  try {
    const res = await fetch(
      `${BASE_API_URL}class/listAssignments/${currClassId}?buid=${MY_BU_ID}`,
      {
        method: "GET",
        headers: GET_DEFAULT_HEADERS(),
      }
    );
    const json = await res.json();
    // console.log("ASSIGNMENT WEIGHT", json); 
    return json;
  } catch (error) {
    console.error('Error fetching assignment weight:', error);
  }
};


// LOGIC for iterating through the mappings INSPIRED from SOURCE:
// 1. https://stackoverflow.com/questions/684672/how-do-i-loop-through-or-enumerate-a-javascript-object 
// 2. https://stackoverflow.com/questions/70685703/setting-dictionary-of-objects-as-type-for-usestate-in-ts-react-function-compon 


export async function calculateStudentFinalGrade(
  studentID: IStudent | string , 
  assignmentWeight: IAssignment[] , 
  studentGrade: IGrade | undefined,
  klass: IUniversityClass | String, 
): Promise< number | undefined> {

  const studentName = studentGrade?.name; 
  const classId = studentGrade?.classId; 


  let finalGrade = 0; 
  
  const currStudentGrade = studentGrade?.grades[0];
  

  // console.log("CURR STUDENT GRADE", currStudentGrade); 

  if (currStudentGrade && assignmentWeight) {
    // console.log("CURR STUDENT GRADE", currStudentGrade);
    for (const [assignmentId, grade] of Object.entries(currStudentGrade)) {
      // console.log(`Assignment ${assignmentId}: ${grade}`);
      for (const assignment of assignmentWeight) {
        if (assignment.assignmentId === assignmentId) {
          // console.log("ASSIGNMENT WEIGHT", assignment.weight);
          finalGrade += assignment.weight * Number(grade);
          // console.log("FINAL GRADE", finalGrade);
        }
      }
    }
  }

  finalGrade = finalGrade / 100;
  return finalGrade;
}



/**
 * You need to write this function! You might want to write more functions to make the code easier to read as well.
 * 
 *  If you are reading here and you haven't read the top of the file...go back.
 * 
 * @param classID The ID of the class for which we want to calculate the final grades
 * @returns Some data structure that has a list of each student and their final grade.
 */


export async function calcAllFinalGrade(classID: IStudent| string): Promise< Map<string, string[]> |undefined> {
  // Get the list of students in the class
  const studentList = await fetchStudentList(classID as string);
  const className = await fetchClassInfo (classID as string)
  // console.log("THIS IS THE CHOSEN CLASS NAME", className?.title)
  // console.log("THIS IS THE CHOSEN SEMESTER", className?.semester)

  

  let finalStudentList = new Map<string, string[]>();

  for (let student of studentList) {
    // IGrade API call 
    const studentGradeList = await fetchStudentGradeList(student, classID as string); 
    if (studentGradeList === undefined) {
      continue;
    } 

    // IAssignment API call 
    const assignmentWeight = await fetchAssignmentWeight(classID as string);
    if (assignmentWeight === undefined) {
      continue;
    } 


    // call calculateStudentFinalGrade for each student in the class 
    const finalGrade = await calculateStudentFinalGrade(student, assignmentWeight as IAssignment[], studentGradeList, classID as string);
    // console.log("FINAL GRADE ahhhh", finalGrade);

    if (finalGrade === undefined) {
      continue;
    }

    // Here, set up the values 
    const studentFinalName = studentGradeList.name;
    finalStudentList.set(student, [studentFinalName, classID as string, className?.title as string, className?.semester as string, finalGrade.toString() ]  );
    // console.log("FINAL STUDENT LIST", finalStudentList);

    // print all of them out 
    // console.log("STUDENT INFO", studentInfo);
    // console.log("STUDENT GRADE LIST", studentGradeList);
    // console.log("ASSIGNMENT WEIGHT", assignmentWeight);    
  }
  // for (const student of studentList) { 
  //   const temp = await calculateStudentFinalGrade(student, new Map(), new Map(), classID as string); 
  // console.log("FINAL STUDENT LIST", finalStudentList);
  return finalStudentList;
}
