import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Select, Typography } from "@mui/material";
/**
 * You will find globals from this file useful!
 */
import {MY_BU_ID, BASE_API_URL, GET_DEFAULT_HEADERS } from "./globals";
import { IUniversityClass, IStudent, IAssignment, IGrade} from "./types/api_types";

// Grid 
import GradeTable from './components/GradeTable';
// Import for selecting the dropdown, source: https://mui.com/components/selects/
import MenuItem from '@mui/material/MenuItem';


function App() {
  // You will need to use more of these!

  // SEE Calculate_grades.tsx for more of them! 

  // E.G Here, ONE of the 3 selected class ID: C123 -> VL519 , C129 -> MA200, C125 -> ST 519 
  const [currClassId, setCurrClassId] = useState<string>("");

  // E.G Here: List of the class json, with classID as identifier
  const [classList, setClassList] = useState<IUniversityClass[]>([]);



  const handleClassSelectChange = (event: any ) => {
    setCurrClassId(event.target.value);
  };


  /**
   * This is JUST an example of how you might fetch some data(with a different API).
   * As you might notice, this does not show up in your console right now.
   * This is because the function isn't called by anything!
   *
   * You will need to lookup how to fetch data from an API using React.js
   * Something you might want to look at is the useEffect hook.
   *
   * The useEffect hook will be useful for populating the data in the dropdown box.
   * You will want to make sure that the effect is only called once at component mount.
   *
   * You will also need to explore the use of async/await.
   *
   */
  // const fetchSomeData = async () => {
  //   const res = await fetch("https://cat-fact.herokuapp.com/facts/", {
  //     method: "GET",
  //   });
  //   const json = await res.json();
  //   console.log(json);
  // };

  
  /****************************************************************************************
   * FETCH CLASS LIST 
   * 
   * Learning about async and await: 
   * 
   * SOURCE: https://blog.logrocket.com/async-await-in-typescript/
  *****************************************************************************************/
  
  const fetchClassList = async () => { 
    // write const res using imports from globals 
    try {
      const res = await fetch(
        `${BASE_API_URL}class/listBySemester/fall2022?buid=${MY_BU_ID}`,
        {
          method: 'GET',
          headers: GET_DEFAULT_HEADERS(),
        }
      );

    const json = await res.json();
    // console.log(json);
    setClassList(json);
    // returns a promise 
    return json;
  } catch (error) {
    console.error('Error fetching class list:', error);
  }
  };

    // Adding a use effect to fetch the class list 
    useEffect(() => {
      const fetchData = async () => {
        // Using async to fetch the data and check for errors 
        try {
          const response = await fetchClassList(); // Assuming fetchClassList returns a Promise
          setClassList(response);
        } catch (error) {
          console.error("The class does not exist:", error);
        }
      };
  
      fetchData();
    }, []);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <Grid container spacing={2} style={{ padding: "1rem" }}>
        <Grid xs={12} container alignItems="center" justifyContent="center">
          <Typography variant="h2" gutterBottom>
            Spark Assessment
          </Typography>
        </Grid>
        <Grid xs={12} md={4}>
          <Typography variant="h4" gutterBottom>
            Select a class
          </Typography>
          <div style={{ width: "100%" }}>
            {/* when the class is selected, highlight it */}
            <Select               
              fullWidth={true}
              label="Class"
              value={currClassId}
              onChange={handleClassSelectChange} > 
            
              {/* You'll need to place some code here to generate the list of items in the selection */}
              {/* insert the class list */}
          {classList.map((universityClass) => (
            <MenuItem value={universityClass.classId} key={universityClass.classId}>
              {universityClass.title}
            </MenuItem>
          ))}

            </Select>
          </div>
        </Grid>
        <Grid xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Final Grades
          </Typography>
          {/* <div> 
            {dummyData()}
          </div> */}
          <div> 
          <GradeTable currClassId= {currClassId}/> {/* Pass the currClassId prop */}
          </div>
          <div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
