import {MY_BU_ID, BASE_API_URL, GET_DEFAULT_HEADERS } from "../../src/globals";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {IUniversityClass, IGrade, IAssignment, IStudent } from "../types/api_types"
import {calcAllFinalGrade}  from "../utils/calculate_grade"




/**
 * You might find it useful to have some dummy data for your own testing.
 * Feel free to write this function if you find that feature desirable.
 * 
 * When you come to office hours for help, we will ask you if you have written
 * this function and tested your project using it.
 */


// SOURCE: 
/* Code snippet format modified from: https://mui.com/material-ui/react-table/  */


const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 90,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.firstName || ''} ${params.row.lastName || ''}`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];

export function dummyData() {
  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        // checkboxSelection
      />
    </div>
  );
}
/**
 * This is the component where you should write the code for displaying the
 * the table of grades.
 *
 * You might need to change the signature of this function.
 *
 */


/**
 * USING THE IDEA OF FUNCTION COMPONENTS, code adapted and adjusted from: https://www.freecodecamp.org/news/react-components-jsx-props-for-beginners/ 
 * for the GradeTable component 
 *
 */

interface GradeTableProps {
  currClassId: string; // Add this prop declaration
}


const GradeTable: React.FC<GradeTableProps> = ({ currClassId }) => {
  // Here, the logic is to check the promise of the final grade and then set the state of the studentFinalGrade in order for it to be parsed and displayed in the table 
  // CODE SNIPPET IS FROM CHAT GPT (UseEffect )

  // CHAT GPT GENERATED CODE with some modification: 
  const [studentFinalGrade, setStudentFinalGrade] = useState<Map<string, string[]>>(new Map());
  const [loading, setLoading] = useState(true);

  
  // console.log('Selected Class ID:', currClassId);

  // The useEffect here is to fetch the final grades and then set the state of the studentFinalGrade, which also performs error check 
  useEffect(() => {
    const fetchFinalGrades = async () => {
      setLoading(true);
      try {
        const finalGradesData = await calcAllFinalGrade(currClassId);
        setStudentFinalGrade(finalGradesData as Map<string, string[]>);
      } catch (error) {
        console.error("Error fetching final grades:", error);
      } finally {
        setLoading(false);
      }
    };

    if (currClassId) {
      fetchFinalGrades();
    }
  }, [currClassId]);

  if (loading) {
    return <div>Loading...</div>;
  }
  // END OF CHAT GPT GENERATED CODE 

  const columns1: GridColDef[] = [ 
    { field: 'SID', headerName: 'Student ID', width: 100}, 
    { field: 'SName', headerName: 'Student Name', width: 150 }, 
    { field: 'CID', headerName: 'Class ID', width: 100 }, 
    { field: 'CName', headerName: 'Class Name', width: 100}, 
    { field: 'Sem', headerName: 'Semester', width: 100 }, 
    { field: 'Fg', headerName: 'Final Grade', width: 100 },
  ];

  // creating the rows for the table
  let rows1 = [
  ];

  const finalGradesArray = Array.from(studentFinalGrade.entries());

  console.log("PRINTING OUT THE FINAL GRADES ARRAY", finalGradesArray);

  // Iterating through a MAP in TSX: 
  // Code inspired by SOURCE: https://stackoverflow.com/questions/43336197/react-how-to-iterate-over-dictionary-in-render-method-using-jsx 

  for (const [key, value] of finalGradesArray) {
    // console.log("PRINTING OUT THE KEY AND THE VALUUUEEE", `${key}: ${value}`);

    // add the values to the row, PUSH works but it adds to the end of the array, UNSHIFT adds to the beginning of the array 
    // Here, due to the order of my output, I use unshift instead of push to reverse the entries 
    rows1.unshift({id: key, SID: key, SName: value[0], CID: value[1], CName: value[2], Sem: value[3], Fg: value[4]});
  }



  return (
    // Render your GradeTable component
    // ...
    <div style={{ height: 800, width: '100%' }}>
      <DataGrid
        rows={rows1}
        columns={columns1}
        // checkboxSelection
      />
    </div>
  );
};

export default GradeTable;